/**
 * Created by gschulz on 04.04.16.
 */
var ElevationService = (function () {

    var _private = {
        instance: this,
        api_key: null,
        api_url: null,
        valid: false,
        cache: {}
    };

    this.init = function () {
        var elevationData = CacheStorage.getCacheByKey('elevation_settings',null);
        if(_.isObject(elevationData) && !_.isUndefined(elevationData.api_key) &&  !_.isUndefined(elevationData.url)){
            _private.api_key = elevationData.api_key;
            _private.api_url = elevationData.url;
            _private.valid = true;
        }
        return this;
    };

    this.getElevationByLocation = function (lat,lon,callBackSuccess,callBackFail) {
        if(!_private.valid
            || typeof lat !== 'number' || typeof lon !== 'number'
            || _.isNaN(lat)|| _.isNaN(lon)
            || typeof callBackSuccess !== 'function'){
            return false;
        }
        if(!_.isUndefined(_private.cache[lat.toString() + '-' + lon.toString()])){
            callBackSuccess.apply(this,[_private.cache[lat.toString() + '-' + lon.toString()]]);
            return;
        }
        if(typeof callBackFail !== 'function'){
            callBackFail = callBackSuccess
        }
        $.ajax({
            url: _private.api_url,
            data: {
                lat: lat,
                lon: lon
            },
            type: 'GET',
            headers: {
                'x-api-key': _private.api_key
            },
            error: function () {
                callBackFail.apply(this,[0]);
            },
            success: function (elevation) {
                if(typeof elevation !== 'number'){
                    callBackFail.apply(this,[0]);
                    return;
                }
                var convert = new MathConvert();
                elevation = convert.convertValue(elevation,'METER','FEET','length');

                _private.cache[lat.toString() + '-' + lon.toString()] = elevation;
                callBackSuccess.apply(this,[elevation]);
            }
        });
    };

    this.updateOnCoordinateChange = function (latElement,lonElement,elevationElement) {
        var elements = $($.map([latElement,lonElement], function (el) {return el.get();}));
        var eventHelper    = new EventHelper();
        elements.unbind('keyup.updateOnCoordinateChangeElevation').on('keyup.updateOnCoordinateChangeElevation',
            function (event) {
                eventHelper.delayCall(
                    function () {
                        _private.instance.getElevationByLocation(
                            parseFloat(latElement.val()),
                            parseFloat(lonElement.val()),
                            function (elevation) {
                                if(typeof elevation !== 'number'){
                                    return;
                                }
                                elevationElement.uVal(elevation).keyup()
                            }
                        );

                    },
                    'updateOnCoordinateChange', [event]);
            }
        );
    };
});